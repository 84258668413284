import Image1 from './1.png'
import Image2 from './2.png'
import Image3 from './3.png'
import Image4 from './4.png'
import Image5 from './5.png'
import Image6 from './6.png'
import Image7 from './7.png'
import Image8 from './8.png'
import Image9 from './9.png'

export const CryptoImages = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9]
